/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@shopify/app-bridge-react'
import { Page, Layout, Card, DescriptionList, Banner } from '@shopify/polaris'
import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'
import { RootStateContext } from '~/components/RootStateProvider'
import { Context } from '~/services'
import { useDeleteRuleMutation, useUpdateRuleMutation } from '~/gql/schema'
import { ConnectionContext } from '../RuleForm/Connection'
import { ConnectionDetailsForm } from '../RuleForm/ConnectionDetails'
import { ConnectionRulesForm } from '../RuleForm/ConnectionRules'
import { FormRule } from './NewProductRule'
import { RuleItem } from '~/repositories/rule.repository'
import { useAlert } from '~/components/Alert/useAlert'
import { AlertFeed } from '~/components/Alert/Feed'

export const ProductRule = (props: { rule: RuleItem | undefined }) => {
  const router = useRouter()
  const { state } = useContext(RootStateContext)
  const [editMode, setEditMode] = useState<boolean>(false)

  const {
    values,
    reset,
    webhookId,
    titleTemplate,
    descriptionTemplate,
    setTitleTemplate,
    setDescriptionTemplate,
  } = useContext(ConnectionContext)

  const updateTemapltes = () => {
    setTitleTemplate(values?.mappings?.find((mapping) => mapping.key === 'Title')?.template ?? '')
    setDescriptionTemplate(values?.mappings?.find((mapping) => mapping.key === 'Description')?.template ?? '')
  }

  const checkValidForm = (rule?: FormRule) => {
    if (rule?.mappings.length === 0 || !rule?.content_model_id || !rule?.rule_name || !rule?.shopify_ref_field) {
      throw new Error('Not valid')
    }
    return
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)

  const [alert] = useAlert()

  const [deleteRule] = useDeleteRuleMutation()
  const [updateRule] = useUpdateRuleMutation()

  const handleSave = async () => {
    if (!props.rule) return
    setSaving(true)
    try {
      checkValidForm(values)
      await Context.ctfManagement(state.config.management_api_key).updateWebhook(webhookId ?? '', {
        name: values?.rule_name,
        contentTypeId: values?.content_model_id,
      })
      const mappings = values?.mappings.map((mapping) => {
        switch (mapping.key) {
          case 'Title':
            return { ...mapping, template: titleTemplate }
          case 'Description':
            return { ...mapping, template: descriptionTemplate }
          default:
            return mapping
        }
      })
      await updateRule({
        variables: {
          input: {
            ...values,
            rule_name: values?.rule_name as string,
            mappings,
            rule_id: props.rule.data_variant.split('#')[1],
          },
        },
      })
      alert.success('The rule has been successfully updated.')
      setEditMode(false)
      setSaving(false)
      router.back()
      return
    } catch (error) {
      alert.error('Please enter rule name, content model, shopify reference field and at least one rule definition.')
      setSaving(false)
      return
    }
  }

  const handleDelete = async () => {
    if (!props?.rule) return
    try {
      await deleteRule({ variables: { rule: props.rule } })
      router.back()
      setDeleting(false)
      return
    } catch (error) {
      alert.error('Something went wrong')
      setDeleting(false)
      return
    }
  }

  return (
    <Page
      title={'Synchronization rule'}
      titleMetadata={
        <Banner status={values?.active ? 'success' : 'warning'}>{values?.active ? 'Active' : 'Inactive'}</Banner>
      }
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: !editMode,
          onAction: () => {
            reset()
            setEditMode(false)
          },
        },
        {
          content: 'Edit',
          disabled: editMode,
          onAction: () => {
            updateTemapltes()
            setEditMode(true)
          },
        },
      ]}
      pagination={{ hasPrevious: true, hasNext: false, onPrevious: () => router.back() }}
      divider={true}
    >
      <Layout>
        <AlertFeed />
        <Layout.Section>
          <Card
            primaryFooterAction={{ content: 'Save', disabled: !editMode, onAction: handleSave, loading: saving }}
            secondaryFooterActions={[
              {
                content: 'Delete',
                onAction: () => setDeleteModalOpen(true),
                destructive: true,
              },
            ]}
            actions={[
              {
                content: 'To home page',
                url: `/embedded?${new URLSearchParams(router.query as Record<string, string>).toString()}`,
                accessibilityLabel: 'Navigate to home page.',
              },
            ]}
          >
            <Card.Section title="Connection details">
              {editMode ? (
                <ConnectionDetailsForm />
              ) : (
                <DescriptionList
                  items={[
                    { term: 'Rule name', description: values?.rule_name },
                    { term: 'Content model', description: values?.content_model_id },
                    { term: 'Shopify reference field', description: values?.shopify_ref_field },
                  ]}
                />
              )}
            </Card.Section>
            <Card.Section title="Connection rules">
              {editMode ? (
                <ConnectionRulesForm />
              ) : (
                <DescriptionList
                  items={
                    values?.mappings?.map((mapping) => ({
                      term: `${mapping.key} template`,
                      description: mapping.template,
                    })) ?? []
                  }
                />
              )}
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          title={'Delete synchronization rule?'}
          primaryAction={{ content: 'Delete rule', onAction: handleDelete, destructive: true, loading: deleting }}
          secondaryActions={[{ content: 'Cancel', onAction: () => setDeleteModalOpen(false) }]}
          message={'This cannot be undone.'}
        ></Modal>
      </div>
    </Page>
  )
}
