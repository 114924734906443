import { Layout } from '@shopify/polaris'
import React, { useContext } from 'react'
import { AlertContext } from '.'
import { AlertBox } from './Box'

export const AlertFeed = () => {
  const { state, dispatch } = useContext(AlertContext)
  if (!state.messages.length) return null
  return (
    <>
      {state.messages.map((msg) => (
        <Layout.Section key={msg.id}>
          <AlertBox
            status={msg.status}
            title={msg.title}
            onDismiss={
              msg.options.dismissEnabled ? () => dispatch({ type: 'remove', payload: { id: msg.id } }) : undefined
            }
          >
            {msg.message}
          </AlertBox>
        </Layout.Section>
      ))}
    </>
  )
}
