import React, { createContext, Dispatch, useMemo, useReducer } from 'react'
import { useCallback, useEffect } from 'react'
import { captureException } from '@sentry/nextjs'
import { Context } from '~/services'
import { freeQuota } from '~/data/subscriptionPlans'
import { useConfigQuery } from '~/gql/schema'
import { ConfigItem } from '~/repositories/config.repository'
import { RuleItem } from '~/repositories/rule.repository'

type FetchedConfig = {
  type: 'fetched-config'
  payload: { config: ConfigItem | null }
}

type FetchedRules = {
  type: 'fetched-rules'
  payload: { rules: RuleItem[]; sync_count: number; product_count: number }
}

type ConfigValid = {
  type: 'config-valid-check'
}

type UpsertedConfig = {
  type: 'upserted-config'
  payload: { config: ConfigItem }
}

type UpdateConfigState = {
  type: 'updated-config-state'
  payload: { state: EntityState }
}

type ToggleConfigEditMode = {
  type: 'toggle-config-edit-mode'
  payload: { edit_mode: boolean }
}

type CancelledSubscription = {
  type: 'cancelled-subscription'
}

export type RootReducerAction =
  | FetchedRules
  | ConfigValid
  | FetchedConfig
  | UpsertedConfig
  | UpdateConfigState
  | ToggleConfigEditMode
  | CancelledSubscription

export enum EntityState {
  IDLE = 'IDLE',
  INVALID = 'INVALID',
  SUCCESS = 'SUCCESS',
}

export interface RootState {
  initialized: boolean
  rulesFetched: boolean
  emptyState: boolean
  config: ConfigItem
  rules: RuleItem[]
  sync_count: number
  product_count: number
  loadingConfig: boolean
  config_state: EntityState
  config_edit_mode: boolean
}

export const rootReducer = (state: RootState, action: RootReducerAction): RootState => {
  switch (action.type) {
    case 'fetched-rules':
      return {
        ...state,
        rulesFetched: true,
        rules: action.payload.rules,
        sync_count: action.payload.sync_count,
        product_count: action.payload.product_count
      }
    case 'config-valid-check':
      return {
        ...state,
        emptyState: false,
      }
    case 'fetched-config':
      const config: ConfigItem | null = action.payload.config
        ? {
            ...action.payload.config,
            plan_name: action.payload.config.plan_name ?? freeQuota.name,
            max_products_synced: action.payload.config.max_products_synced ?? freeQuota.max_products_synced,
            monthly_sync_quota: action.payload.config.monthly_sync_quota ?? freeQuota.monthly_sync_quota,
          }
        : null
      return {
        ...state,
        config: config ?? state.config,
        initialized: true,
        loadingConfig: false,
      }
    case 'upserted-config':
      return {
        ...state,
        config: action.payload.config,
        emptyState: false,
        config_state: EntityState.SUCCESS,
        config_edit_mode: false,
      }
    case 'updated-config-state':
      return {
        ...state,
        config_state: action.payload.state,
      }
    case 'toggle-config-edit-mode':
      return {
        ...state,
        config_edit_mode: action.payload.edit_mode,
      }
    case 'cancelled-subscription':
      const prevConfig = state?.config as ConfigItem
      return {
        ...state,
        config: {
          ...prevConfig,
          max_products_synced: freeQuota.max_products_synced,
          monthly_sync_quota: freeQuota.monthly_sync_quota,
          plan_name: freeQuota.name,
        },
      }
  }
}

export const intialConfig: ConfigItem = {
  endpoint: '',
  data_variant: 'CONFIG',
  space_id: '',
  management_api_key: '',
  environment_id: '',
  created_at: new Date().getTime()
}

const initialRootState: RootState = {
  initialized: false,
  emptyState: true,
  rulesFetched: false,
  config: intialConfig,
  rules: [],
  sync_count: 0,
  product_count: 0,
  config_state: EntityState.IDLE,
  config_edit_mode: false,
  loadingConfig: false,
}

interface RootStateContextProps {
  state: RootState
  dispatch: Dispatch<RootReducerAction>
}

export const RootStateContext = createContext<RootStateContextProps>({
  state: initialRootState,
  dispatch: () => {},
})

export const RootStateProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(rootReducer, initialRootState)
  const configQuery = useConfigQuery()

  const getCtfApiKey = useCallback(async () => {
    const config = configQuery.data?.config
    try {
      const ctfClient = Context.ctfManagement(config?.management_api_key)
      await ctfClient.initialize(config?.space_id ?? '', config?.environment_id ?? '')
    } catch (error) {
      captureException(error)
    } finally {
      dispatch({ type: 'fetched-config', payload: { config: config ?? null } })
    }
  }, [configQuery])

  useEffect(() => {
    getCtfApiKey()
  }, [getCtfApiKey])

  const value = useMemo(() => {
    return {
      state,
      dispatch,
    }
  }, [state])
  return <RootStateContext.Provider value={value}>{props.children}</RootStateContext.Provider>
}
