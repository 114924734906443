import { Modal, useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import {
  Button,
  Card,
  DisplayText,
  Icon,
  Layout,
  List,
  Page,
  ResourceItem,
  ResourceList,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from '@shopify/polaris'
import { CircleTickMajor } from '@shopify/polaris-icons'
import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'
import { RootStateContext } from '~/components/RootStateProvider'
import { SubscriptionItem, subscriptionPlans } from '~/data/subscriptionPlans'
import { useFormatter } from '~/hooks/useFormatter'
import {
  useCancelSubscriptionMutation,
  useCheckDiscountCodeMutation,
  useUpdateSubscriptionMutation,
} from '~/gql/schema'
import { useAlert } from '~/components/Alert/useAlert'
import { AlertFeed } from '~/components/Alert/Feed'

export const SubscriptionPage = () => {
  const router = useRouter()
  const [edit, setEdit] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const [enterDiscountCode, setEnterDiscountCode] = useState(false)
  const [discountCode, setDiscountCode] = useState('')

  const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] = useState(false)

  const [updateSubscription, updateSubscriptionResult] = useUpdateSubscriptionMutation()
  const [checkCode, checkCodeResult] = useCheckDiscountCodeMutation()
  const [cancelSubscription, cancelSubscriptionResult] = useCancelSubscriptionMutation()

  const { formatCurrency } = useFormatter()

  const app = useAppBridge()

  const { state, dispatch } = useContext(RootStateContext)
  const [alert] = useAlert()

  const handleUpdate = async () => {
    const plan = subscriptionPlans.find((plan) => plan.id === selectedItems[0]) as SubscriptionItem
    try {
      const result = await updateSubscription({
        variables: {
          input: {
            planName: plan.name,
            amount: plan?.monthly_amount ?? 0,
            discountPercentage: checkCodeResult.data ? 1 : 0,
            usageTerms: plan?.onUsage?.terms,
            usageCappedAmount: plan?.onUsage?.cappedAmount,
          },
        },
      })
      const redirect = Redirect.create(app)
      redirect.dispatch(Redirect.Action.REMOTE, {
        url: result.data.update.confirmationUrl,
      })
    } catch (error: any) {
      alert.error(error.message)
    }
  }

  const checkDiscountCode = async () => {
    try {
      await checkCode({ variables: { input: { code: discountCode } } })
    } catch (error) {
      alert.error('Invalid discount code.')
    }
  }

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription()
      dispatch({ type: 'cancelled-subscription' })
      setEdit(false)
    } catch (error) {
      alert.error('Something went wrong while cancelling subscription. Please contact support.')
      setCancelSubscriptionModalOpen(false)
    }
  }

  const renderItem = (item: SubscriptionItem) => {
    const shortcutActions = item?.url
      ? [
          {
            content: 'Contact us',
            accessibilityLabel: `Contact us to know more about ${item.name} subscription plan.`,
            url: item.url,
            external: true
          },
        ]
      : []
    return (
      <ResourceItem
        shortcutActions={shortcutActions}
        persistActions={!!item?.url}
        id={item.id}
        accessibilityLabel={`Choose subscription plan ${item.name}`}
        onClick={(id) => (id ? setSelectedItems([id]) : {})}
      >
        <DisplayText size="small">{item.name}</DisplayText>
        <br />
        <List>
          <List.Item>
            {item.monthly_sync_quota
              ? `${item.max_products_synced} products - ${item.monthly_sync_quota} synchronisation events/month`
              : `${item.description}`}
          </List.Item>
          {item.onUsage && <List.Item>{item.onUsage.terms}</List.Item>}
        </List>
      </ResourceItem>
    )
  }

  return (
    <Page
      title={edit ? 'Change plan' : 'Current plan'}
      pagination={{ hasPrevious: true, onPrevious: () => router.back() }}
    >
      <Layout>
        <AlertFeed />
        <Layout.Section>
          {!edit && (
            <Card
              actions={[
                {
                  content: 'To home page',
                  url: `/embedded?${new URLSearchParams(router.query as Record<string, string>).toString()}`,
                  accessibilityLabel: 'Navigate to home page.',
                },
              ]}
              primaryFooterAction={{ content: 'Change plan', onAction: () => setEdit(true) }}
            >
              <Card.Section title="Your current plan is">
                <TextContainer>
                  <DisplayText>{state?.config?.plan_name}</DisplayText>
                  <List>
                    <List.Item>{`${state?.config?.max_products_synced} products`}</List.Item>
                    <List.Item>{`${state?.config?.monthly_sync_quota} synchronization events/month`}</List.Item>
                    {state?.config?.usage_terms && <List.Item>{state.config.usage_terms}</List.Item>}
                  </List>
                </TextContainer>
              </Card.Section>
            </Card>
          )}
          {edit && (
            <>
              <Card
                actions={[
                  {
                    content: 'To home page',
                    url: `/embedded?${new URLSearchParams(router.query as Record<string, string>).toString()}`,
                    accessibilityLabel: 'Navigate to home page.',
                  },
                ]}
                secondaryFooterActions={[
                  {
                    content: 'Cancel subscription',
                    destructive: true,
                    onAction: () => setCancelSubscriptionModalOpen(true),
                    disabled: state?.config?.plan_name === 'Free',
                  },
                  {
                    content: 'Cancel',
                    onAction: () => {
                      setSelectedItems([])
                      setEdit(false)
                      setEnterDiscountCode(false)
                      setDiscountCode('')
                    },
                  },
                ]}
              >
                <Card.Header title="Plan selection" />
                <Card.Section title="Subscription plans">
                  <ResourceList
                    selectable
                    showHeader={false}
                    items={subscriptionPlans.filter(
                      (plan) => plan.name !== state?.config?.plan_name && plan.name !== 'Free',
                    )}
                    renderItem={renderItem}
                    selectedItems={selectedItems}
                    onSelectionChange={(ids: string[]) => {
                      setSelectedItems((prev) => ids.filter((id) => !prev.includes(id)))
                      setEnterDiscountCode(false)
                      setDiscountCode('')
                    }}
                  ></ResourceList>
                </Card.Section>
                {selectedItems.length > 0 && selectedItems[0] !== '4' && (
                  <Card.Section title="Discount code">
                    {!enterDiscountCode && (
                      <>
                        <TextStyle variation="strong">{'If you have a discount code '}</TextStyle>
                        <Button plain monochrome onClick={() => setEnterDiscountCode(true)}>
                          please click here
                        </Button>
                      </>
                    )}
                    {enterDiscountCode && (
                      <TextField
                        label="Discount code"
                        value={discountCode}
                        onChange={(value) => setDiscountCode(value)}
                        connectedLeft={
                          <Button
                            onClick={() => {
                              setDiscountCode('')
                              setEnterDiscountCode(false)
                            }}
                          >
                            Cancel
                          </Button>
                        }
                        connectedRight={
                          !checkCodeResult.data ? (
                            <Button primary onClick={checkDiscountCode} loading={checkCodeResult.loading}>
                              Check validity
                            </Button>
                          ) : (
                            <Icon source={CircleTickMajor} color="success" />
                          )
                        }
                      />
                    )}
                  </Card.Section>
                )}
              </Card>
              <div>
                <Modal
                  open={cancelSubscriptionModalOpen}
                  onClose={() => setCancelSubscriptionModalOpen(false)}
                  title={'Cancel subscription?'}
                  primaryAction={{
                    content: 'Cancel subscription',
                    onAction: () => handleCancelSubscription(),
                    destructive: true,
                    loading: cancelSubscriptionResult.loading,
                  }}
                  secondaryActions={[{ content: 'Cancel', onAction: () => setCancelSubscriptionModalOpen(false) }]}
                  message={'You can purchase any other subscription plan anytime in the future.'}
                />
              </div>
            </>
          )}
          {selectedItems.length > 0 && selectedItems[0] !== '4' && (
            <Card
              primaryFooterAction={{
                content: 'Update plan',
                onAction: handleUpdate,
                loading: updateSubscriptionResult.loading,
              }}
              title="Pricing details"
            >
              <Card.Section>
                <Stack>
                  <Stack.Item>
                    <TextStyle variation="subdued">
                      {`${subscriptionPlans.find((plan) => plan.id === selectedItems[0])?.name}: Pay monthly`}
                    </TextStyle>
                  </Stack.Item>
                  <Stack.Item>
                    <span style={{ fontWeight: 600, textDecoration: checkCodeResult.data ? 'line-through' : '' }}>
                      {' '}
                      {formatCurrency(
                        subscriptionPlans.find((plan) => plan.id === selectedItems[0])?.monthly_amount ?? 0,
                      )}
                    </span>
                    {checkCodeResult.data && <TextStyle variation="strong"> {formatCurrency(0)}</TextStyle>}
                  </Stack.Item>
                </Stack>
                <div style={{ height: 24 }} />
                <TextStyle variation="subdued">{`Charges are processed through Shopify's invoicing system and appear directly on the Shopify invoice.`}</TextStyle>
              </Card.Section>
            </Card>
          )}
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default SubscriptionPage
