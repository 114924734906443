import { Frame, Loading } from '@shopify/polaris'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RootStateContext } from '~/components/RootStateProvider'
import { ProductRule } from '~/containers/ProductRule/ProductRule'
import { Connection } from '~/containers/RuleForm/Connection'
import { useRuleLazyQuery } from '~/gql/schema'
import { RuleItem } from '~/repositories/rule.repository'

export const ProductRulePage = () => {
  const { state } = useContext(RootStateContext)
  const slugArray = window.location.pathname.split('/')
  const ruleId = slugArray[slugArray.length - 1]

  const [ruleQuery, ruleQueryResponse] = useRuleLazyQuery()

  const [rule, setRule] = useState<RuleItem>()

  const fetchRule = useCallback(async () => {
    if (!ruleId) return

    await ruleQuery({ variables: { id: ruleId.split('%23')[1] } })
  }, [ruleId, ruleQuery])

  useEffect(() => {
    if (ruleQueryResponse.loading || !ruleQueryResponse.called) return
    const rule = ruleQueryResponse.data?.rule
    rule && setRule(rule)
  }, [ruleQueryResponse.called, ruleQueryResponse.data?.rule, ruleQueryResponse.loading])

  useEffect(() => {
    fetchRule()
  }, [fetchRule])

  if (!state.initialized)
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )

  return (
    <Connection
      initialValues={{
        rule_name: rule?.rule_name,
        content_model_id: rule?.content_model_id,
        shopify_ref_field: rule?.shopify_ref_field,
        mappings: rule?.mappings,
        active: rule?.active,
      }}
      webhookId={rule?.webhook_id}
    >
      <ProductRule rule={rule} />
    </Connection>
  )
}

export default ProductRulePage
