import { useAppBridge } from '@shopify/app-bridge-react'
import { ApolloClient, InMemoryCache, ApolloProvider as Provider, Observable, ApolloLink } from '@apollo/client'
import { RestLink } from 'apollo-link-rest'
import { ClientApplication } from '@shopify/app-bridge'
import { getSessionToken } from '@shopify/app-bridge-utils'
import { onError } from '@apollo/link-error'
import { useEffect } from 'react'
import { useAlert } from '../Alert/useAlert'
import { RetryLink } from '@apollo/client/link/retry'
import { initializeAuthProcess } from '~/hooks/useOAuth'

export const ApolloProvider: React.FC = (props) => {
  const app = useAppBridge()
  const link = ApolloLink.from([retryLink, errorLink, restLink(app)])
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  })

  return (
    <>
      <Provider client={client}>{props.children}</Provider>
      <ApolloErrorAlert />
    </>
  )
}

function customFetch(app: ClientApplication<any>) {
  return async (uri: RequestInfo, options?: RequestInit | undefined): Promise<Response> => {
    const token = await getSessionToken(app)
    return await fetch(uri, { ...options, headers: { ...options?.headers, Authorization: `Bearer ${token}` } })
  }
}

// ####################
// #   RestLink       #
// ####################

const restLink = (app: ClientApplication<any>) =>
  new RestLink({
    uri: `${app.localOrigin}/api/`,
    customFetch: customFetch(app),
    credentials: 'include',
  })

// ######################
// #   ErrorLink        #
// ######################

let emitError: (error: Error) => void
const errorObserver = new Observable<Error>((observer) => {
  emitError = (msg) => observer.next(msg)
})
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`,
      )
    })
    graphQLErrors.forEach((error) => emitError(error))
  }
  if (networkError) {
    emitError(networkError)
    if ((networkError as any).statusCode === 401) {
      initializeAuthProcess()
    }
  }
})
export const ApolloErrorAlert = () => {
  const [alert] = useAlert()
  useEffect(() => {
    const subscriber = errorObserver.subscribe((error) =>
      alert.error(`Unfortunately an error occured: ${error.message}`),
    )
    return () => subscriber.unsubscribe()
  }, [alert])
  return null
}

// #####################
// #    RetryLink      #
// #####################

const retryLink = new RetryLink({
  delay: { initial: 300, max: Infinity, jitter: true },
  attempts: { max: 5 },
})
