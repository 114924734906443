import { Layout, Page, Frame, Loading } from '@shopify/polaris'
import React, { useCallback, useContext, useEffect } from 'react'
import { ConfigContextProvider } from '~/containers/ContentfulConfig/ConfigForm'
import { ContentfulConfig } from '~/containers/ContentfulConfig'
import { RootStateContext } from '~/components/RootStateProvider'
import { useRulesLazyQuery } from '~/gql/schema'
import { AlertFeed } from '~/components/Alert/Feed'

const Index = () => {
  const { state, dispatch } = useContext(RootStateContext)

  const { config, initialized } = state

  const checkCtfConfig = useCallback(async () => {
    return !!config?.space_id && !!config?.environment_id && !!config?.management_api_key
  }, [config])

  const [rulesQuery, rulesQueryResponse] = useRulesLazyQuery()

  useEffect(() => {
    if (!rulesQueryResponse.called || rulesQueryResponse.loading) return
    const result = rulesQueryResponse.data
    dispatch({
      type: 'fetched-rules',
      payload: {
        rules: result?.payload?.rules ?? [],
        sync_count: result?.payload?.sync_count ?? 0,
        product_count: result?.payload?.product_count ?? 0,
      },
    })
  }, [dispatch, rulesQueryResponse.called, rulesQueryResponse.data, rulesQueryResponse.loading])

  const initialize = useCallback(async () => {
    const valid = await checkCtfConfig()
    if (valid) {
      dispatch({ type: 'config-valid-check' })
      await rulesQuery()
    }
  }, [checkCtfConfig, dispatch, rulesQuery])

  useEffect(() => {
    initialize()
  }, [initialize])

  if (!initialized)
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )

  return (
    <Page>
      <ConfigContextProvider initialValues={config ?? {}}>
        <Layout>
          <AlertFeed />
          <ContentfulConfig />
        </Layout>
      </ConfigContextProvider>
    </Page>
  )
}

export default Index
