import { FormLayout, Stack, Button, Popover, ActionList } from '@shopify/polaris'
import { DeleteMinor } from '@shopify/polaris-icons'
import { ContentType, ContentTypeFieldValidation } from 'contentful-management'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { MappingMediaField } from '~/components/MappingMediaField'
import { MappingTextfield } from '~/components/MappingTextfield'
import { RootStateContext } from '~/components/RootStateProvider'
import { RuleItemMapping } from '~/repositories/rule.repository'
import { Context } from '~/services'
import { ConnectionContext } from './Connection'

export const ConnectionRulesForm = () => {
  const { state } = useContext(RootStateContext)
  const { values, setValue, titleTemplate, setTitleTemplate, descriptionTemplate, setDescriptionTemplate } = useContext(
    ConnectionContext,
  )

  const splitTemplateInArray = (template: string) => {
    const regExp = /[^{{{\}]+(?=}}})/g
    return template.match(regExp)
  }

  const chosenInitialsOptions = useMemo(() => {
    return splitTemplateInArray(values?.mappings?.find((mapping) => mapping.type === 'media')?.template ?? '')
  }, [values?.mappings])

  const [contentTypes, setContentTypes] = useState<ContentType[]>([])
  const [popoverShown, setPopoverShown] = useState<boolean>(false)
  const [chosenOptions, setChosenOptions] = useState<string[]>(chosenInitialsOptions ?? [])

  const fetchContentTypes = useCallback(async () => {
    const types = await Context.ctfManagement(state.config.management_api_key).getContentTypes()
    setContentTypes(types)
  }, [state.config.management_api_key])

  useEffect(() => {
    fetchContentTypes()
  }, [fetchContentTypes])

  const textFieldOptions = useMemo(() => {
    const textFieldTypes = ['Symbol', 'RichText', 'Text', 'Integer', 'Number', 'Date', 'Boolean']
    const fields =
      contentTypes
        ?.find((type) => type.sys.id === values?.content_model_id)
        ?.fields?.filter((field) => textFieldTypes.includes(field.type))
        .map((field) => ({ label: field.name, value: field.id })) ?? []
    return [{ label: 'Choose field', value: '' }].concat(fields)
  }, [contentTypes, values?.content_model_id])

  const titleFieldOptions = useMemo(() => {
    const textFieldTypes = ['Symbol']
    const fields =
      contentTypes
        ?.find((type) => type.sys.id === values?.content_model_id)
        ?.fields?.filter((field) => textFieldTypes.includes(field.type))
        .map((field) => ({ label: field.name, value: field.id })) ?? []
    return [{ label: 'Choose field', value: '' }].concat(fields)
  }, [contentTypes, values?.content_model_id])

  const checkValidations = (validations: ContentTypeFieldValidation[] | undefined) => {
    return (
      validations?.length === 0 ||
      (validations &&
        validations.length > 0 &&
        validations.some((validation) => validation?.linkMimetypeGroup?.includes('image')))
    )
  }

  const mediaFieldOptions = useMemo(() => {
    const fields =
      contentTypes
        ?.find((type) => type.sys.id === values?.content_model_id)
        ?.fields?.filter(
          (field) =>
            (field.type === 'Link' && field.linkType === 'Asset' && checkValidations(field?.validations)) ||
            (field.type === 'Array' &&
              field.items?.type === 'Link' &&
              field.items?.linkType === 'Asset' &&
              checkValidations(field.items?.validations)),
        )
        .map((field) => ({ label: field.name, value: field.id })) ?? []
    return [{ label: 'Choose field', value: '' }].concat(fields)
  }, [contentTypes, values?.content_model_id])

  const appendField = useCallback(
    (mapping: RuleItemMapping, value: string) => {
      switch (mapping.key) {
        case 'Title':
          setTitleTemplate(`${titleTemplate}{{{${value}}}}`)
          break
        case 'Description':
          setDescriptionTemplate(`${descriptionTemplate}{{{${value}}}}`)
          break
        default:
          break
      }
    },
    [descriptionTemplate, setDescriptionTemplate, setTitleTemplate, titleTemplate],
  )

  const handleChangeField = (mapping: RuleItemMapping, value: string) => {
    switch (mapping.key) {
      case 'Title':
        setTitleTemplate(value)
        break
      case 'Description':
        setDescriptionTemplate(value)
        break
      default:
        break
    }
  }

  const handleChangeMediaField = (mapping: RuleItemMapping, idx: number, value: string[]) => {
    let mappings = values?.mappings ?? []
    const template = value.reduce((acc, curr) => `{{{${curr}}}}${acc}`, '')
    setChosenOptions(value)
    mappings?.splice(idx, 1, { ...mapping, template })
  }

  const removeMediaField = (mapping: RuleItemMapping, templateIdx: number, valueIdx: number) => {
    let mappings = values?.mappings ?? []
    let options = splitTemplateInArray(mappings[templateIdx].template) ?? []
    options.splice(valueIdx, 1) ?? []

    const newTemplate = options.reduce((acc, curr) => `{{{${curr}}}}${acc}`, '')
    setChosenOptions(options)
    mappings?.splice(templateIdx, 1, { ...mapping, template: newTemplate })
  }

  const removeRule = (idx: number) => {
    let mappings = values?.mappings ?? []
    mappings?.splice(idx, 1)
    setValue('mappings', mappings)
  }

  const availableProductFields: RuleItemMapping[] = [
    { key: 'Title', type: 'string', template: '' },
    { key: 'Description', type: 'string', template: '' },
    { key: 'Images', type: 'media', template: '' },
  ]

  const setTextfieldValue = (key: RuleItemMapping['key']): string => {
    switch (key) {
      case 'Title':
        return titleTemplate
      case 'Description':
        return descriptionTemplate
      default:
        return ''
    }
  }

  const newRelationActions = availableProductFields
    .filter((field) => !values?.mappings?.some((mapping) => mapping.key === field.key))
    .map((field) => {
      return {
        content: `New ${field.key} relation`,
        onAction: () => {
          setPopoverShown(false)
          setValue('mappings', (values?.mappings ?? [])?.concat(field))
        },
      }
    })

  if (!values?.shopify_ref_field) return null

  return (
    <FormLayout>
      {values?.mappings &&
        (values.mappings as RuleItemMapping[]).length > 0 &&
        (values.mappings as RuleItemMapping[]).map((mapping, idx) => (
          <>
            {mapping.type === 'string' && (
              <Stack key={`mapping-textfield-${idx}`} alignment="trailing">
                <Stack.Item fill>
                  <MappingTextfield
                    value={setTextfieldValue(mapping.key)}
                    options={mapping.key === 'Title' ? titleFieldOptions : textFieldOptions}
                    onSelect={(value) => appendField(mapping, value)}
                    onChange={(evt: CustomEvent<Tagify.ChangeEventData<Tagify.TagData>>) =>
                      handleChangeField(
                        mapping,
                        (evt.detail.tagify as any).state.lastOriginalValueReported
                          .replaceAll('{{{{"value":"', '{{{')
                          .replaceAll('"}}}}', '}}}'),
                      )
                    }
                    idx={idx}
                    mappingKey={mapping.key}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Button
                    onClick={() => removeRule(idx)}
                    destructive
                    icon={DeleteMinor}
                    accessibilityLabel={`Remove ${mapping.key} rule`}
                  ></Button>
                </Stack.Item>
              </Stack>
            )}
            {mapping.type === 'media' && (
              <Stack key={`mapping-mediafield-${idx}`} alignment="trailing">
                <Stack.Item fill>
                  <MappingMediaField
                    mappingKey={mapping.key}
                    options={mediaFieldOptions}
                    idx={idx}
                    chosenOptions={chosenOptions}
                    onChange={(value) => handleChangeMediaField(mapping, idx, value)}
                    onRemove={(fieldIdx) => removeMediaField(mapping, idx, fieldIdx)}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Button
                    onClick={() => removeRule(idx)}
                    destructive
                    icon={DeleteMinor}
                    accessibilityLabel={`Remove ${mapping.key} rule`}
                  ></Button>
                </Stack.Item>
              </Stack>
            )}
          </>
        ))}
      {newRelationActions.length > 0 && (
        <div>
          <Popover
            active={popoverShown}
            activator={
              <Button primary onClick={() => setPopoverShown((prev) => !prev)} disclosure>
                Add new relation
              </Button>
            }
            onClose={() => setPopoverShown(false)}
          >
            <ActionList actionRole="newRelation" items={newRelationActions} />
          </Popover>
        </div>
      )}
    </FormLayout>
  )
}
