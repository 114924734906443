import React from 'react'
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react'
import { BrowserRouter } from 'react-router-dom'
import { PolarisProvider } from '../PolarisProvider'
import { RootStateProvider } from '../RootStateProvider'
import { RoutePropagator } from '../RoutePropagator'
import { ApolloProvider } from '../ApolloProvider'
import { NextComponentType, NextPageContext } from 'next'
import { useHost } from '~/hooks/useHost'
import { AlertProvider } from '../Alert'

export const EmbeddedApp: React.FC<{ Component: NextComponentType<NextPageContext, any, {}>; pageProps: any }> = (
  props,
) => {
  const host = useHost()

  if (!host) return null

  return (
    <BrowserRouter>
      <AppBridgeProvider
        config={{ apiKey: process.env.NEXT_PUBLIC_SHOPIFY_APP_API_KEY as string, host, forceRedirect: true }}
      >
        <PolarisProvider>
          <AlertProvider>
            <RoutePropagator />
            <ApolloProvider>
              <RootStateProvider>{props.children}</RootStateProvider>
            </ApolloProvider>
          </AlertProvider>
        </PolarisProvider>
      </AppBridgeProvider>
    </BrowserRouter>
  )
}
