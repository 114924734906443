import { ContentfulManagement } from '~/services/contentful'

// Frontend Singleton
export const Context = (function () {
  let ctfManagement: ContentfulManagement

  function createCtfManagementInstance(apiKey: string) {
    return new ContentfulManagement(apiKey)
  }

  return {
    ctfManagement: function (apiKey?: string) {
      if (!ctfManagement) {
        ctfManagement = createCtfManagementInstance(apiKey ?? '')
        return ctfManagement
      }
      return ctfManagement
    },
  }
})()
