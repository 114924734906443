import '@shopify/polaris/dist/styles.css'
import React from 'react'
import { AppProps } from 'next/app'
import { EmbeddedApp } from '~/components/EmbeddedApp'
import AppRouter from '~/components/AppRouter'
import PageStructure from '~/components/PageStructure'

const App = (props: AppProps) => {
  const { Component, pageProps } = props

  return (
    <EmbeddedApp Component={Component} pageProps={pageProps}>
      <AppRouter />
      <PageStructure />
    </EmbeddedApp>
  )
}

export default App
