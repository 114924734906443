import { Banner, BannerStatus, TextStyle } from '@shopify/polaris'
import React, { ReactNode } from 'react'

type AlertBoxProps = {
  title?: string
  status?: BannerStatus
  onDismiss?: () => void
  action?: ReactNode
}

export const AlertBox: React.FC<AlertBoxProps> = (props) => {
  const { action = null, ...rest } = props
  return (
    <Banner status={props.status} {...rest}>
      <span>
        <TextStyle variation="strong">
          {props.children}
          {action}
        </TextStyle>
      </span>
    </Banner>
  )
}
