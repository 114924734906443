import { Page, Layout, Card } from '@shopify/polaris'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useState } from 'react'
import { AlertFeed } from '~/components/Alert/Feed'
import { useAlert } from '~/components/Alert/useAlert'
import { useCreateRuleMutation } from '~/gql/schema'
import { RuleItem } from '~/repositories/rule.repository'
import { ConnectionContext } from '../RuleForm/Connection'
import { ConnectionDetailsForm } from '../RuleForm/ConnectionDetails'
import { ConnectionRulesForm } from '../RuleForm/ConnectionRules'

export type FormRule = Omit<
  RuleItem,
  'active' | 'rule_type' | 'endpoint' | 'data_variant' | 'webhook_url' | 'webhook_id'
>

export const NewProductRule = () => {
  const { values, titleTemplate, descriptionTemplate } = useContext(ConnectionContext)

  const [createRule] = useCreateRuleMutation()

  const router = useRouter()
  const [alert] = useAlert()

  const checkValidForm = (rule?: FormRule) => {
    if (rule?.mappings.length === 0 || !rule?.content_model_id || !rule?.rule_name || !rule?.shopify_ref_field) {
      throw new Error('Not valid')
    }
    return
  }
  const [saving, setSaving] = useState<boolean>(false)

  const saveRule = useCallback(async () => {
    setSaving(true)
    try {
      checkValidForm(values)
      const mappings =
        values?.mappings.map((mapping) => {
          switch (mapping.key) {
            case 'Title':
              return { ...mapping, template: titleTemplate }
            case 'Description':
              return { ...mapping, template: descriptionTemplate }
            default:
              return mapping
          }
        }) ?? []
      await createRule({
        variables: {
          rule: {
            rule_type: 'Product',
            mappings,
            content_model_id: values?.content_model_id as string,
            shopify_ref_field: values?.shopify_ref_field as string,
            rule_name: values?.rule_name as string,
          },
        },
      })
      alert.success('The rule has been successfully created.')
      router.back()
      setSaving(false)
      return
    } catch (error) {
      alert.error('Please enter rule name, content model, shopify reference field and at least one rule definition.')
      setSaving(false)
      return
    }
  }, [alert, createRule, descriptionTemplate, router, titleTemplate, values])

  const saveDisabled =
    !values?.shopify_ref_field || !values?.content_model_id || !values.rule_name || values.mappings.length === 0

  return (
    <Page
      title={'New synchronization rule'}
      pagination={{ hasPrevious: true, hasNext: false, onPrevious: () => router.back() }}
      divider={true}
    >
      <Layout>
        <AlertFeed />
        <Layout.Section>
          <Card
            primaryFooterAction={{
              content: 'Save',
              onAction: saveRule,
              loading: saving,
              disabled: saveDisabled,
            }}
            actions={[
              {
                content: 'To home page',
                url: `/embedded?${new URLSearchParams(router.query as Record<string, string>).toString()}`,
                accessibilityLabel: 'Navigate to home page.',
              },
            ]}
          >
            <Card.Section title="Connection details">
              <ConnectionDetailsForm />
            </Card.Section>
            <Card.Section title="Connection rules">
              <ConnectionRulesForm />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}
