import { useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { Card, EmptySearchResult, Frame, Layout, Loading, Page, ResourceItem, ResourceList } from '@shopify/polaris'
import { Entry } from 'contentful-management'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from '~/components/Link'
import { RootStateContext } from '~/components/RootStateProvider'
import { Context } from '~/services'
import { useContentfulEntriesOfProductLazyQuery } from '~/gql/schema'
import { AlertFeed } from '~/components/Alert/Feed'

export const ProductCtfEntriesList = () => {
  const router = useRouter()
  const { state } = useContext(RootStateContext)
  const shopifyProductId = router.query?.id

  const [ctfEntriesQuery, ctfEntriesQueryResponse] = useContentfulEntriesOfProductLazyQuery()

  const app = useAppBridge()

  const [ctfEntries, setCtfEntries] = useState<Entry[]>([])

  const redirectToContentful = useCallback(
    async (entryId: string) => {
      const space = Context.ctfManagement(state.config.management_api_key).space
      const redirect = Redirect.create(app)
      redirect.dispatch(Redirect.Action.REMOTE, {
        url: `https://app.contentful.com/spaces/${space?.sys?.id ?? ''}/entries/${entryId}`,
        newContext: true,
      })
    },
    [app, state.config.management_api_key],
  )

  const fetchCtfEntries = useCallback(async () => {
    if (!shopifyProductId || ctfEntriesQueryResponse.loading || ctfEntriesQueryResponse.called) return
    await ctfEntriesQuery({ variables: { id: shopifyProductId as string } })
  }, [shopifyProductId, ctfEntriesQueryResponse.loading, ctfEntriesQueryResponse.called, ctfEntriesQuery])

  useEffect(() => {
    if (!ctfEntriesQueryResponse.called || ctfEntriesQueryResponse.loading) return
    const ctfEntryIds = ctfEntriesQueryResponse.data?.entries?.ids ?? []
    if (ctfEntryIds.length === 0) {
      setCtfEntries([])
      return
    }
    if (ctfEntryIds.length === 1) {
      redirectToContentful(ctfEntryIds[0])
      Context.ctfManagement()
        .getEntry(ctfEntryIds[0])
        .then((ctfEntry) => {
          setCtfEntries([ctfEntry])
        })
      return
    }
    Promise.all(
      ctfEntryIds.map(async (id: string) => {
        const entry = await Context.ctfManagement(state.config.management_api_key).getEntry(id)
        return entry
      }),
    ).then((contentfulEntries) => {
      setCtfEntries(contentfulEntries)
    })
  }, [
    ctfEntriesQueryResponse.called,
    ctfEntriesQueryResponse.data?.entries?.ids,
    ctfEntriesQueryResponse.loading,
    redirectToContentful,
    state.config.management_api_key,
  ])

  useEffect(() => {
    fetchCtfEntries()
  }, [fetchCtfEntries, shopifyProductId])

  const renderItem = (item: Entry, id: string, idx: number) => {
    return (
      <ResourceItem
        id={item.sys.id}
        onClick={() => {}}
        accessibilityLabel={`Navigate to contentful entry with id ${item.sys.id}`}
      >
        <h3>
          <strong>
            {`${item.sys.contentType.sys.id} entry ${idx + 1}`}{' '}
            <Link url={`https://app.contentful.com/spaces/${item.sys.space.sys.id}/entries/${item.sys.id}`} external>
              Go to entry
            </Link>
          </strong>
        </h3>
      </ResourceItem>
    )
  }

  const emptyStateMarkup =
    ctfEntriesQueryResponse.called && ctfEntries.length === 0 ? (
      <EmptySearchResult title={'No entries found'} withIllustration />
    ) : undefined

  if (!state.initialized)
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )

  return (
    <Page
      title="Entries"
      pagination={{ hasPrevious: true, hasNext: false, onPrevious: () => router.back() }}
      divider={true}
    >
      <Layout>
        <AlertFeed />
        <Layout.Section>
          <Card>
            <ResourceList
              items={ctfEntries}
              renderItem={renderItem}
              selectable={false}
              loading={ctfEntriesQueryResponse.loading}
              emptyState={emptyStateMarkup}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default ProductCtfEntriesList
