import { BannerStatus } from '@shopify/polaris'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { AlertContext, AlertMessage, AlertOptions } from '.'
import { v4 as uuid } from 'uuid'

const defaultOptions: AlertOptions = {
  duration: 3000,
  autoHide: true,
  dismissEnabled: true,
}

export const useAlert = (options: Partial<AlertOptions> = {}) => {
  const opts = useMemo(() => ({ ...defaultOptions, ...options }), [options])
  const { state, dispatch } = useContext(AlertContext)
  const localMessageIds = useRef([] as string[])
  const dismissAlertMessage = useCallback(
    (id: string) => {
      dispatch({ type: 'remove', payload: { id } })
      localMessageIds.current = localMessageIds.current.filter((localMsgId) => localMsgId !== id)
    },
    [dispatch],
  )
  useEffect(() => {
    return () => {
      localMessageIds.current.forEach(dismissAlertMessage)
    }
  }, [dismissAlertMessage])
  const addAlert = useCallback(
    (status: BannerStatus, title: string, config: Partial<AlertOptions> = {}) => {
      const options = { ...opts, ...config }
      const id = uuid()
      localMessageIds.current.push(id)
      dispatch({
        type: 'add',
        payload: { id, status, title, options },
      })

      if (options.autoHide) {
        setTimeout(() => {
          if (localMessageIds.current.some((msgId) => msgId === id)) {
            dismissAlertMessage(id)
          }
        }, options.duration)
      }
    },
    [dismissAlertMessage, dispatch, opts],
  )
  const alert = useMemo(
    () => ({
      error: (title: string, options?: Partial<AlertOptions>) => {
        addAlert('critical', title, options)
      },
      warning: (title: string, options?: Partial<AlertOptions>) =>
        addAlert('warning', title, options),
      success: (title: string, options?: Partial<AlertOptions>) =>
        addAlert('success', title, options),
    }),
    [addAlert],
  )
  return [alert, state.messages] as [typeof alert, AlertMessage[]]
}
