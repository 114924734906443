export interface SubscriptionItem {
  id: string
  name: string
  monthly_sync_quota?: number
  max_products_synced?: number
  monthly_amount?: number
  yearly_amount?: number
  url?: string
  description?: string
  onUsage?: {
    terms: string
    cappedAmount: number
    unitAmount: number
    addProducts: number
    addSyncEvents: number
  }
}

export const freeQuota = {
  monthly_sync_quota: 50,
  max_products_synced: 5,
  name: 'Free'
}

export const subscriptionPlans: SubscriptionItem[] = [
  {
    id: '1',
    ...freeQuota,
    monthly_amount: 0,
  },
  {
    id: '2',
    name: 'Starter',
    monthly_sync_quota: 300,
    max_products_synced: 30,
    monthly_amount: 15,
    onUsage: {
      terms: '$5.00 USD for extra 10 products and 100 synchronisations',
      cappedAmount: 25,
      unitAmount: 5,
      addProducts: 10,
      addSyncEvents: 100
    }
  },
  {
    id: '3',
    name: 'Professional',
    monthly_sync_quota: 1500,
    monthly_amount: 49,
    max_products_synced: 150,
    onUsage: {
      terms: '$5.00 USD for extra 30 products and 300 synchronisations',
      cappedAmount: 75,
      unitAmount: 5,
      addProducts: 30,
      addSyncEvents: 300
    }
  },
  {
    id: '4',
    name: 'Enterprise (Talk to us)',
    description: 'SLA, Priority Support, Multi Language',
    url: 'mailto:office@do.vision',
  },
]
