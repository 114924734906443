import { useEffect, useState } from 'react'

export const useHost = () => {
  const [host, setHost] = useState<string>()
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location) {
      const queryHost = new URLSearchParams(window.location.search).get('host')
      if (queryHost) setHost(queryHost)
    }
  }, [])
  return host
}
