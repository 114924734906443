import { BannerStatus } from '@shopify/polaris'
import React, { createContext, useReducer } from 'react'

export type AlertType = 'success' | 'warning' | 'error' | 'info'
export type AlertOptions = {
  duration: number
  autoHide: boolean
  dismissEnabled: boolean
}
export type AlertMessage = {
  id: string
  status: BannerStatus
  message?: string
  options: AlertOptions
  title: string
}
type AddAlertAction = {
  type: 'add'
  payload: AlertMessage
}

type RemoveAlertAction = {
  type: 'remove'
  payload: { id: string }
}

type AlertReducerAction = AddAlertAction | RemoveAlertAction

interface AlertState {
  messages: AlertMessage[]
}
interface AlertContextInterface {
  state: AlertState
  dispatch: React.Dispatch<AlertReducerAction>
}
export const AlertContext = createContext<AlertContextInterface>({
  state: { messages: [] },
  dispatch: (() => {}) as React.Dispatch<AlertReducerAction>,
})

const alertReducer = (state: AlertState, action: AlertReducerAction): AlertState => {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }
    case 'remove':
      return {
        ...state,
        messages: state.messages.filter((msg) => msg.id !== action.payload.id),
      }
  }
}

export const AlertProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(alertReducer, { messages: [] })
  return <AlertContext.Provider value={{ state, dispatch }}>{children}</AlertContext.Provider>
}
