import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { ConfigItem } from '~/repositories/config.repository'
import { RuleItem } from '~/repositories/rule.repository'

export const useRulesLazyQuery = () => {
  return useLazyQuery<{
    payload: {
      rules: RuleItem[] | undefined | null
      sync_count: number | undefined | null
      product_count: number | undefined | null
    }
  }>(rules, { fetchPolicy: 'network-only' })
}

const rules = gql`
  query Rules {
    payload @rest(path: "stores/rules") {
      rules {
        rule_name
        shopify_ref_field
        webhook_id
        webhook_url
        active
        rule_type
        mappings
        endpoint
        data_variant
      }
      sync_count
      product_count
    }
  }
`

export const useRuleLazyQuery = () => {
  return useLazyQuery<{ rule: RuleItem | undefined }>(rule, { fetchPolicy: 'network-only' })
}

const rule = gql`
  query Rule($id: String) {
    rule(id: $id) @rest(path: "stores/rules?{args}") {
      rule_name
      shopify_ref_field
      webhook_id
      webhook_url
      active
      rule_type
      mappings
      endpoint
      data_variant
      content_model_id
    }
  }
`

export const useContentfulEntriesOfProductLazyQuery = () => {
  return useLazyQuery<{ entries: { ids: string[] } }>(contentfulEntriesOfProduct)
}

const contentfulEntriesOfProduct = gql`
  query CtfEntriesOfProduct($id: String!) {
    entries(id: $id) @rest(path: "ctf-entries?{args}") {
      ids
    }
  }
`

export const useConfigQuery = () => {
  return useQuery<{ config: ConfigItem | undefined }>(config, { fetchPolicy: 'network-only' })
}
export const useConfigLazyQuery = () => {
  return useLazyQuery<{ config: ConfigItem | undefined }>(config, { fetchPolicy: 'network-only' })
}

const config = gql`
  query Config {
    config @rest(path: "stores/config") {
      space_id
      management_api_key
      environment_id
      last_synced_month
      sync_count
      product_count
      monthly_sync_quota
      max_products_synced
      plan_name
      endpoint
      data_variant
      usage_capped_amount
      usage_unit_amount
      usage_terms
    }
  }
`

export const useCreateConfigMutation = () => {
  return useMutation(createConfig)
}

const createConfig = gql`
  fragment CreateConfigInput on REST {
    space_id
    management_api_key
    environment_id
    created_at
  }

  mutation CreateConfig($config: CreateConfigInput!) {
    create(config: $config) @rest(path: "stores/config", method: "POST", bodyKey: "config") {
      message
    }
  }
`

export const useCreateRuleMutation = () => {
  return useMutation(createRule)
}

const createRule = gql`
  fragment CreateRuleInput on REST {
    rule_type
    mappings
    rule_name
    shopify_ref_field
    content_model_id
  }

  mutation CreateRule($rule: CreateRuleInput!) {
    create(rule: $rule) @rest(path: "stores/rules", method: "POST", bodyKey: "rule") {
      message
    }
  }
`

export const useDeleteRuleMutation = () => {
  return useMutation(deleteRule)
}

const deleteRule = gql`
  fragment DeleteRuleInput on REST {
    data_variant
    content_model_id
    shopify_ref_field
  }

  mutation DeleteRule($rule: DeleteRuleInput!) {
    delete(rule: $rule) @rest(path: "stores/rules/delete", method: "POST", bodyKey: "rule") {
      status
    }
  }
`

export const useUpdateRuleMutation = () => {
  return useMutation(updateRule)
}

const updateRule = gql`
  fragment UpdateRuleInput on REST {
    rule_id
    rule_name
    content_model_id
    mappings
    shopify_ref_field
    active
  }

  mutation UpdateRule($input: UpdateRuleInput!) {
    update(input: $input) @rest(path: "stores/rules", method: "PUT", bodyKey: "input") {
      message
    }
  }
`

export const useExecuteRuleMutation = () => {
  return useMutation(executeRule)
}

const executeRule = gql`
  fragment ExecuteRuleInput on REST {
    content_model_id
    mappings
    shopify_ref_field
  }

  mutation ExecuteRule($rule: ExecuteRuleInput) {
    execute(rule: $rule) @rest(path: "stores/rules/execute", method: "POST", bodyKey: "rule") {
      message
    }
  }
`

export const useUpdateSubscriptionMutation = () => {
  return useMutation(updateSubscription)
}

const updateSubscription = gql`
  fragment UpdateSubscriptionInput on REST {
    planName
    amount
    discountPercentage
    usageTerms
    usageCappedAmount
  }

  mutation UpdateSubscription($input: UpdateSubscriptionInput) {
    update(input: $input) @rest(path: "subscription", method: "POST", bodyKey: "input") {
      confirmationUrl
    }
  }
`

export const useCheckDiscountCodeMutation = () => {
  return useMutation(checkDiscountCode)
}

const checkDiscountCode = gql`
  fragment CheckDiscountCodeInput on REST {
    code
  }

  mutation CheckDiscountCode($input: CheckDiscountCodeInput) {
    check(input: $input) @rest(path: "discount-code/check", method: "POST", bodyKey: "input") {
      message
    }
  }
`

export const useCancelSubscriptionMutation = () => {
  return useMutation(cancelSubscription)
}

const cancelSubscription = gql`
  mutation CancelSubscription {
    cancel @rest(path: "subscription/delete", method: "POST") {
      message
    }
  }
`
