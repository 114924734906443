import React from 'react'
import { useOAuth } from '~/hooks/useOAuth'
import { Frame, Loading } from '@shopify/polaris'

const Index = () => {
  useOAuth()
  return (
    <div style={{ height: '100px' }}>
      <Frame>
        <Loading />
      </Frame>
    </div>
  )
}

export default Index
