import { Badge, ResourceItem, ResourceList, TextStyle } from '@shopify/polaris'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '~/services'
import { Modal } from '@shopify/app-bridge-react'
import { RootStateContext } from '~/components/RootStateProvider'
import { useDeleteRuleMutation, useExecuteRuleMutation, useRulesLazyQuery, useUpdateRuleMutation } from '~/gql/schema'
import { RuleItem } from '~/repositories/rule.repository'
import { useAlert } from '~/components/Alert/useAlert'
import { useRouter } from 'next/router'

interface RulesListProps {
  type: 'products'
  rules: RuleItem[]
}

export const RulesList = (props: RulesListProps) => {
  const { type, rules } = props
  const { state, dispatch } = useContext(RootStateContext)

  const [deleteRule, deleteRuleResult] = useDeleteRuleMutation()
  const [updateRule] = useUpdateRuleMutation()
  const [executeRule, executeRuleResult] = useExecuteRuleMutation()
  const [getRules, getRulesResult] = useRulesLazyQuery()

  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const [alert] = useAlert()
  const router = useRouter()

  const toggleActive = async (ids: string[], active: boolean) => {
    await Promise.all(
      ids.map(async (id) => {
        const rule = props.rules.find((rule) => rule.data_variant === id)
        await Context.ctfManagement(state.config.management_api_key).toggleStatus(rule?.webhook_id ?? '', active)
        try {
          await updateRule({
            variables: { input: { rule_name: rule?.rule_name as string, rule_id: id.split('#')[1], active } },
          })
          alert.success('The rule has been successfully updated..')
          return
        } catch (error: any) {
          alert.error(error.message)
        }
      }),
    )
    await getRules()
  }

  const syncNow = async (id: string) => {
    const rule = props.rules.find((rule) => rule.data_variant === id)
    if (!rule) return
    try {
      alert.success('The synchronisation has been started.')
      await executeRule({ variables: { rule } })
      alert.success('The synchronisation has been executed.')
    } catch (error) {
      alert.error('Something went wrong.')
    } finally {
      await getRules()
    }
  }

  const deleteRules = useCallback(
    async (ids: string[]) => {
      await Promise.all(
        ids.map(async (id) => {
          const rule = props.rules.find((rule) => rule.data_variant === id)
          if (!rule) return
          try {
            await deleteRule({ variables: { rule } })
            alert.success('The rule has been deleted successfully.')
            return
          } catch (error) {
            alert.error('Something went wrong.')
          }
        }),
      )
      setDeleteModalOpen(false)
      await getRules()
    },
    [alert, deleteRule, getRules, props.rules],
  )

  useEffect(() => {
    if (!getRulesResult.called || getRulesResult.loading) return
    const result = getRulesResult.data
    dispatch({
      type: 'fetched-rules',
      payload: {
        rules: result?.payload?.rules ?? [],
        sync_count: result?.payload?.sync_count ?? 0,
        product_count: result?.payload?.product_count ?? 0,
      },
    })
  }, [dispatch, getRulesResult.called, getRulesResult.data, getRulesResult.loading])


  const promotedBulkActions = [{ content: 'Delete Items', onAction: () => setDeleteModalOpen(true) }]

  const bulkActions = [
    {
      content: 'Activate',
      onAction: () => toggleActive(selectedItems, true),
    },
    {
      content: 'Deactivate',
      onAction: () => toggleActive(selectedItems, false),
    },
  ]

  const renderItem = (item: RuleItem) => {
    const { data_variant, rule_name, active } = item
    const shortcutActions = active
      ? [
          {
            content: 'Sync now',
            accessibilityLabel: `Execute the syncronisation of the ${type} rule ${rule_name}`,
            onAction: () => syncNow(data_variant),
          },
          {
            content: 'Deactivate',
            accessibilityLabel: `Deactivate ${type} rule ${rule_name}`,
            onAction: () => toggleActive([data_variant], false),
          },
        ]
      : [
          {
            content: 'Activate',
            accessibilityLabel: `activate ${type} rule ${rule_name}`,
            onAction: () => toggleActive([data_variant], true),
          },
        ]
    return (
      <ResourceItem
        id={data_variant}
        url={`/embedded/${type}-rule/${data_variant.replace('#', '%23')}?${new URLSearchParams(
          router.query as Record<string, string>,
        ).toString()}`}
        accessibilityLabel={`View details for ${type} rule ${rule_name}`}
        shortcutActions={shortcutActions}
        media={<Badge status={active ? 'success' : 'warning'}>{active ? 'Active' : 'Inactive'}</Badge>}
      >
        <h3>
          <TextStyle variation={active ? 'strong' : 'subdued'}>{rule_name}</TextStyle>
        </h3>
      </ResourceItem>
    )
  }

  if ((!rules || !rules?.length || rules?.length === 0) && !getRulesResult.loading)
    return (
      <p style={{ marginTop: 8 }}>
        <TextStyle variation="strong">There are no rules found yet.</TextStyle>
      </p>
    )
  return (
    <>
      <ResourceList
        resourceName={{ singular: `${type} rule`, plural: `${type} rules` }}
        items={rules}
        selectedItems={selectedItems}
        onSelectionChange={(ids: string[]) => {
          setSelectedItems(
            ids.map((id) => {
              if (id.startsWith('RULE')) return id
              return rules[Number(id)].data_variant
            }),
          )
        }}
        promotedBulkActions={promotedBulkActions}
        bulkActions={bulkActions}
        renderItem={renderItem}
        selectable
        loading={getRulesResult.loading || executeRuleResult.loading}
      />
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          title={'Delete synchronization rule?'}
          primaryAction={{
            content: 'Delete rule',
            onAction: () => deleteRules(selectedItems),
            destructive: true,
            loading: deleteRuleResult.loading,
          }}
          secondaryActions={[{ content: 'Cancel', onAction: () => setDeleteModalOpen(false) }]}
          message={'This cannot be undone.'}
        ></Modal>
      </div>
    </>
  )
}
