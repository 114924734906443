import { ClientAPI, createClient, Space, Environment, Locale } from 'contentful-management'

export class ContentfulManagement {
  private apiKey: string
  private client: ClientAPI
  public space: Space | undefined
  private env: Environment | undefined
  private baseUrl: string | undefined

  constructor(apiKey: string) {
    this.apiKey = apiKey
    this.baseUrl = process.env.NEXT_PUBLIC_CONTENTFUL_WEBHOOK_URL ?? ''
    try {
      this.client = createClient({ accessToken: this.apiKey })
    } catch (error) {
      throw new Error('Api Key required')
    }
  }

  async getSpace(spaceId: string) {
    try {
      this.space = await this.client.getSpace(spaceId)
      return this.space
    } catch (error) {
      throw error
    }
  }

  async getEnvironment(env: string) {
    if (!this.space) {
      throw new Error('No space id is configured yet')
    }
    try {
      this.env = await this.space.getEnvironment(env)
      return this.env
    } catch (error) {
      throw error
    }
  }

  async initialize(spaceId: string, env: string): Promise<ContentfulManagement> {
    await this.getSpace(spaceId)
    await this.getEnvironment(env)
    return this;
  }

  async getLocales(): Promise<Locale[]> {
    if (!this.env) {
      throw new Error('No environment id is configured')
    }
    try {
      const collection = await this.env.getLocales()
      return collection.items
    } catch (error) {
      throw error
    }
  }

  async getDefaultLocaleCode(): Promise<string> {
    if (!this.env) {
      throw new Error('No environment id is configured')
    }
    try {
      const locales = await this.getLocales()
      const defaultLocale = locales.find((locale) => locale.default)
      if (defaultLocale) {
        return defaultLocale.code
      }
      throw new Error('No default locale found')
    } catch (error) {
      throw error
    }
  }

  async getContentTypes() {
    if (!this.env) {
      throw new Error('No environment is configured')
    }
    try {
      const contentTypes = await this.env.getContentTypes()
      return contentTypes.items
    } catch (error) {
      throw error
    }
  }

  async createWebhook(endpoint: string, name: string, contentTypeId: string) {
    if (!this.space) {
      throw new Error('No space id is configured yet')
    }
    return await this.space.createWebhook({
      name,
      url: `${this.baseUrl}/published`,
      topics: ['Entry.publish'],
      filters: [{ equals: [{ doc: 'sys.contentType.sys.id' }, contentTypeId] }],
      active: true,
      headers: [{ key: 'X-Shopify-Store', value: endpoint }],
    })
  }

  async updateWebhook(webhookId: string, params: { name?: string; contentTypeId?: string }) {
    if (!this.space) {
      throw new Error('No space id is configured yet')
    }
    const webhook = await this.space.getWebhook(webhookId)
    if (params.name) {
      webhook.name = params.name
    }
    if (params.contentTypeId) {
      webhook.filters = [{ equals: [{ doc: 'sys.contentType.sys.id' }, params.contentTypeId] }]
    }
    return await webhook.update()
  }

  async deleteWebhook(webhookId: string) {
    if (!this.space) {
      throw new Error('No space id is configured yet')
    }
    const webhook = await this.space.getWebhook(webhookId)
    await webhook.delete()
  }

  async toggleStatus(webhookId: string, active: boolean) {
    if (!this.space) {
      throw new Error('No space id is configured yet')
    }
    const webhook = await this.space.getWebhook(webhookId)
    webhook.active = active
    return await webhook.update()
  }

  async retrieveAsset(assetId: string) {
    if (!this.env) {
      throw new Error('No environment id is configured yet')
    }
    return this.env.getAsset(assetId)
  }

  async getEntry(entryId: string) {
    if (!this.env) {
      throw new Error('No environment id is configured yet')
    }
    return this.env.getEntry(entryId)
  }

  async getEntries(content_type: string) {
    if (!this.env) {
      throw new Error('No environment id is configured yet')
    }
    const entries = await this.env.getEntries({ content_type })
    return entries.items
  }
}
