import useFormFields, { Bindings, SetValueFn } from 'lib/hooks/useFormFields'
import { mergeDeepRight } from 'ramda'
import React, { createContext, useMemo, useState } from 'react'
import { RuleItemMapping } from '~/repositories/rule.repository'

interface ConnectionContextProps {
  bindings: Bindings<FormValues> | undefined
  values: FormValues | undefined
  setValue: SetValueFn<FormValues>
  reset: () => void
  webhookId?: string
  titleTemplate: string
  descriptionTemplate: string
  setTitleTemplate: React.Dispatch<React.SetStateAction<string>>
  setDescriptionTemplate: React.Dispatch<React.SetStateAction<string>>
}

const initialFormValues: FormValues = {
  rule_name: '',
  shopify_ref_field: '',
  content_model_id: '',
  mappings: [],
  active: true,
}

interface FormValues {
  rule_name: string
  shopify_ref_field: string
  content_model_id: string
  mappings: RuleItemMapping[]
  active: boolean
}

export const ConnectionContext = createContext<ConnectionContextProps>({
  bindings: undefined,
  values: undefined,
  setValue: () => {},
  reset: () => {},
  titleTemplate: '',
  descriptionTemplate: '',
  setTitleTemplate: () => {},
  setDescriptionTemplate: () => {},
})

export const Connection: React.FC<{ initialValues: Optional<FormValues>; webhookId?: string }> = (props) => {
  const initialValues = useMemo(() => mergeDeepRight(initialFormValues, props.initialValues), [
    props.initialValues,
  ]) as FormValues

  const { bindings, values, setValue, reset } = useFormFields(initialValues, { resetOnInitialValueChange: true })
  const [titleTemplate, setTitleTemplate] = useState<string>(
    values?.mappings?.find((mapping) => mapping.key === 'Title')?.template ?? '',
  )
  const [descriptionTemplate, setDescriptionTemplate] = useState<string>(
    values?.mappings?.find((mapping) => mapping.key === 'Description')?.template ?? '',
  )
  const value = useMemo(() => {
    return {
      bindings,
      values,
      setValue,
      reset,
      webhookId: props.webhookId,
      titleTemplate,
      setTitleTemplate,
      descriptionTemplate,
      setDescriptionTemplate,
    }
  }, [bindings, values, setValue, reset, props.webhookId, titleTemplate, descriptionTemplate])

  return <ConnectionContext.Provider value={value}>{props.children}</ConnectionContext.Provider>
}
