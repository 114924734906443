import { FormLayout, TextField, Select } from '@shopify/polaris'
import { ContentType } from 'contentful-management'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { RootStateContext } from '~/components/RootStateProvider'
import { Context } from '~/services'
import { ConnectionContext } from './Connection'

export const ConnectionDetailsForm = () => {
  const { state } = useContext(RootStateContext)
  const [contentTypes, setContentTypes] = useState<ContentType[]>([])

  const fetchContentTypes = useCallback(async () => {
    const types = await Context.ctfManagement(state.config.management_api_key).getContentTypes()
    setContentTypes(types)
  }, [state.config.management_api_key])

  const { bindings, values, setValue } = useContext(ConnectionContext)

  useEffect(() => {
    fetchContentTypes()
  }, [fetchContentTypes])

  const contentModelOptions = useMemo(() => {
    return [{ label: 'Choose content model', value: '' }].concat(
      contentTypes?.map((type) => ({ label: type.name, value: type.sys.id })),
    )
  }, [contentTypes])

  const referenceFieldOptions = useMemo(() => {
    const fields =
      contentTypes
        ?.find((type) => type.sys.id === values?.content_model_id)
        ?.fields.filter((field) => field.type === 'Symbol')
        .map((field) => ({ label: field.name, value: field.id })) ?? []
    return [{ label: 'Choose field', value: '' }].concat(fields)
  }, [contentTypes, values?.content_model_id])

  return (
    <FormLayout>
      <TextField
        {...bindings?.rule_name}
        label="Name"
        onChange={(value) => setValue('rule_name', value)}
        inputMode="text"
        id="ruleName"
      />
      <Select
        {...bindings?.content_model_id}
        label="Content model"
        options={contentModelOptions}
        onChange={(value) => setValue('content_model_id', value)}
        id="contentModelId"
      />
      <Select
        {...bindings?.shopify_ref_field}
        label="Shopify reference field"
        options={referenceFieldOptions}
        onChange={(value) => setValue('shopify_ref_field', value)}
        id="refFieldId"
        helpText={
          <span>
            {
              'Shopify reference field is required to establish one-to-one mappings between content stored in Contentful and Shopify products information.'
            }
          </span>
        }
      />
    </FormLayout>
  )
}
