import { useEffect } from 'react'
import axios from 'axios'

export const useOAuth = () => {
  useEffect(() => {
    if (typeof window == 'undefined' || !window.location) return
    initializeAuthProcess()
  }, [])
}

export const initializeAuthProcess = () => {
  const query = Object.fromEntries(new URLSearchParams(window.location.search))
  axios
    .post('/api/auth/shopify/login', { query })
    .then((response) => {
      if (!response.data?.redirectTo) return
      window.location.href = response.data.redirectTo
    })
    .catch((err) => console.error(err))
}
