import { ResourcePicker, useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { SelectPayload } from '@shopify/app-bridge/actions/ResourcePicker'
import {
  Layout,
  Banner,
  Button,
  EmptyState,
  Card,
  DescriptionList,
  Stack,
  ProgressBar,
  TextStyle,
  Link,
  FooterHelp,
} from '@shopify/polaris'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RootStateContext } from '~/components/RootStateProvider'
import { freeQuota } from '~/data/subscriptionPlans'
import { Context } from '~/services'
import { useConfigLazyQuery, useCreateConfigMutation } from '~/gql/schema'
import { RulesList } from '../RulesList'
import { ConfigContext } from './ConfigForm'
import { ConfigForm } from './ConfigForm/form'
import { ConfigItem } from '~/repositories/config.repository'
import { useAlert } from '~/components/Alert/useAlert'

export const ContentfulConfig = () => {
  const imageUrl = 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'
  const { state, dispatch } = useContext(RootStateContext)
  const { values, reset } = useContext(ConfigContext)
  const router = useRouter()

  const [createConfig, createConfigResult] = useCreateConfigMutation()
  const [configQuery, configQueryResponse] = useConfigLazyQuery()

  const { config, emptyState, rules, sync_count, product_count } = state
  const app = useAppBridge()

  const [openResourcePicker, setOpenResourcePicker] = useState<boolean>(false)

  const [alert] = useAlert()

  const checkCtfConfig = async (config: Omit<ConfigItem, 'data_variant' | 'endpoint' | 'created_at'> | undefined) => {
    const ctfManagement = Context.ctfManagement(config?.management_api_key)
    await ctfManagement.initialize(config?.space_id ?? '', config?.environment_id ?? 'master')
  }

  const handleSubmit = useCallback(
    async (isNew?: boolean) => {
      try {
        await checkCtfConfig(values)
        await createConfig({
          variables: {
            config: {
              ...values,
              environment_id: !!values?.environment_id ? values.environment_id : 'master',
              monthly_sync_quota: config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota,
              max_products_synced: config?.max_products_synced ?? freeQuota.max_products_synced,
              plan_name: config?.plan_name ?? freeQuota.name,
              created_at: isNew ? new Date().getTime() : undefined,
            },
          },
        })
        dispatch({
          type: 'upserted-config',
          payload: {
            config: {
              ...state.config,
              data_variant: 'CONFIG',
              environment_id: values?.environment_id ?? 'master',
              space_id: values?.space_id ?? '',
              management_api_key: values?.management_api_key ?? '',
            },
          },
        })

        await Context.ctfManagement(values?.management_api_key).initialize(
          values?.space_id ?? '',
          values?.environment_id || 'master',
        )
        alert.success('The configuration has been successfully saved.')
        return
      } catch (error) {
        alert.error('Please enter valid Space Id, Api key and Environment.')
        reset()
      }
    },
    [
      alert,
      config?.max_products_synced,
      config?.monthly_sync_quota,
      config?.plan_name,
      createConfig,
      dispatch,
      reset,
      state.config,
      values,
    ],
  )

  const handleSelection = (resources: SelectPayload) => {
    const idArray = resources.selection[0].id.split('/')
    const id = idArray[idArray.length - 1]
    setOpenResourcePicker(false)
    const redirect = Redirect.create(app)
    redirect.dispatch(
      Redirect.Action.APP,
      `/embedded/ctf-entries?${new URLSearchParams({ ...(router.query as Record<string, string>), id }).toString()}`,
    )
  }

  useEffect(() => {
    if (!configQueryResponse.called || configQueryResponse.loading) return
    const config = configQueryResponse.data?.config ?? null
    dispatch({ type: 'fetched-config', payload: { config } })
  }, [configQueryResponse.called, configQueryResponse.data?.config, configQueryResponse.loading, dispatch])

  return (
    <>
      {!emptyState && !configQueryResponse.loading && (
        <Layout.Section>
          <Banner status="info">
            <span>
              <TextStyle variation="strong">
                {`You are currently on a ${config?.plan_name ?? freeQuota.name} plan limited to ${
                  config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota
                } synchronisation events and ${
                  config?.max_products_synced ?? freeQuota.max_products_synced
                } products. `}
              </TextStyle>
              <Link url="/embedded/subscription">Update now</Link>
              <br />
              <TextStyle variation="subdued">{`You have updated your subscription plan, but changes are not applied yet? `}</TextStyle>
              <Button plain monochrome onClick={configQuery}>
                Refresh now
              </Button>
            </span>
          </Banner>
        </Layout.Section>
      )}
      {!emptyState &&
        (sync_count > (config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota) * 0.9 ||
          product_count > (config?.max_products_synced ?? freeQuota.max_products_synced) * 0.9) && (
          <Layout.Section>
            <Banner status="warning">
              <span>
                <TextStyle variation="strong">
                  {`You have used ${product_count} / ${
                    config?.max_products_synced ?? freeQuota.max_products_synced
                  } products and ${sync_count} / ${
                    config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota
                  } synchronisation events included in the ${config?.plan_name ?? freeQuota.name} plan. `}
                </TextStyle>
                <Link url="/embedded/subscription">Upgrade now</Link>
              </span>
            </Banner>
          </Layout.Section>
        )}
      {emptyState && (
        <Layout.Section>
          <EmptyState heading="Configure Contentful Connection" image={imageUrl}>
            <p style={{ paddingBottom: '1rem' }}>
              Configure Contentful Space Id to synchronize marketing purpose product informations between headless CMS
              and Shopify for higher flexibility.
            </p>
            <ConfigForm />
            <div style={{ paddingTop: '2rem' }}></div>
            <Button primary loading={createConfigResult.loading} onClick={handleSubmit}>
              Configure
            </Button>
          </EmptyState>
        </Layout.Section>
      )}
      {!emptyState && (
        <>
          <Layout.AnnotatedSection
            title="Contentful Configuration Details"
            description="The space Id, Management Api Key and Environment Id will be used to establish the connection between the CMS and your store."
          >
            <Card
              sectioned
              actions={[
                {
                  content: 'Edit',
                  disabled: state.config_edit_mode,
                  onAction: () => dispatch({ type: 'toggle-config-edit-mode', payload: { edit_mode: true } }),
                },
              ]}
              primaryFooterAction={{
                content: 'Save',
                onAction: handleSubmit,
                loading: createConfigResult.loading,
                disabled: !state.config_edit_mode,
              }}
              secondaryFooterActions={[
                {
                  content: 'Cancel',
                  disabled: !state.config_edit_mode,
                  onAction: () => {
                    reset()
                    dispatch({ type: 'toggle-config-edit-mode', payload: { edit_mode: false } })
                  },
                },
              ]}
            >
              {state.config_edit_mode ? (
                <ConfigForm />
              ) : (
                <DescriptionList
                  items={[
                    { term: 'Contentful Space Id', description: values?.space_id },
                    {
                      term: 'Contentful Management API Key',
                      description: `${values?.management_api_key.substring(0, 5)}...`,
                    },
                    { term: 'Contentful Environment', description: values?.environment_id },
                  ]}
                />
              )}
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Synchronization Rules"
            description="You set up one or more connections between Products in your store and Content Models in your Contentful Space"
          >
            <Card>
              <Card.Header
                actions={[
                  {
                    content: 'New rule',
                    url: `/embedded/products-rule?${new URLSearchParams(
                      router.query as Record<string, string>,
                    ).toString()}`,
                  },
                ]}
                title={'Products Synchronization Rules'}
              />
              <Card.Section>
                <Stack>
                  <Stack.Item fill>
                    <ProgressBar
                      progress={(sync_count / (config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota)) * 100}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <p>
                      {sync_count} / {config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota}
                    </p>
                  </Stack.Item>
                </Stack>
                <TextStyle variation="subdued">Synchronization limit is applied</TextStyle>

                <RulesList type="products" rules={rules} />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Contentful connections"
            description="You can select a shopify product and look up what contentful entries it is connected to."
          >
            <Card>
              <Card.Header title="Connections" />
              <Card.Section>
                <Button onClick={() => setOpenResourcePicker(true)} primary>
                  Select a product
                </Button>
                <ResourcePicker
                  resourceType="Product"
                  showVariants={false}
                  open={openResourcePicker}
                  onSelection={(resources) => handleSelection(resources)}
                  allowMultiple={false}
                  onCancel={() => setOpenResourcePicker(false)}
                />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <FooterHelp>
            Questions?{' '}
            <Link external url="mailto:office@do.vision">
              Contact us
            </Link>
          </FooterHelp>
        </>
      )}
    </>
  )
}
